import React from 'react'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import { object } from 'prop-types'

// Import Components
import CardCta from 'components/CardCta'
import { Article, Community, ProductServiceReviews as Reviews } from '@jelly/components'

// Styled Components via Emotion
const SectionWrapper = styled.section`
	position: relative;

	${({ theme }) => theme.MQ.M} {
		height: 138px;
	}
`
const CoreValuesWrapper = styled.div`
	box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
	display: block;
	left: 0;
	right: 0;
	top: -3vh;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	width: 90%;

	${({ theme }) => theme.MQ.M} {
		display: flex;
		flex-direction: row;
		position: absolute;
	}

	${({ theme }) => theme.MQ.L} {
		top: -138px;
		${({ theme }) => theme.maxWidth.L};
		width: calc(100% - 160px);
	}
`

const propTypes = {
	theme: object.isRequired,
}

/**
 * This is the Core Values Components on the Home Page
 * Strings together the CTA Cards to create the core value cards
 * that float at the top of the home page
 */
function CoreValues({ theme }) {
	return (
		<SectionWrapper>
			<CoreValuesWrapper>
				<CardCta
					background="#fff"
					cta="Expert Advice"
					description="Navigate your career with help from professionals who have been there."
					icon={<Reviews />}
				/>
				<CardCta
					background={theme.colors.lightGrey}
					cta="Recommendations"
					description="Our editorial team can help you select the right product, service or tool."
					icon={<Community />}
				/>
				<CardCta
					background="#fff"
					cta="Reviews"
					description="We conduct objective, hands-on reviews that span more than 200 categories."
					icon={<Article />}
				/>
			</CoreValuesWrapper>
		</SectionWrapper>
	)
}

CoreValues.propTypes = propTypes

export default withTheme(CoreValues)
