import React from 'react'
import styled from '@emotion/styled'
import { array } from 'prop-types'

// Import Components
import CardShortContent from 'components/CardShortContent'
import { Link } from '@jelly/ui'

// Styled Components via Emotion
const SectionWrapper = styled.section`
	background: ${({ theme }) => theme.colors.lightGrey};
	padding: 10px 0;
`
const ArticlesWrapper = styled.div`
	margin: 50px auto;
	width: 80%;

	${({ theme }) => theme.MQ.M} {
		margin: 70px auto;
		${({ theme }) => theme.maxWidth.L};
		width: 100%;
	}
`
const SubHeader = styled.div`
	${({ theme }) => theme.text.sectionHeaderSmall};
	margin: auto;
	text-align: center;
`
const Header = styled.h2`
	${({ theme }) => theme.header.h2};
	color: ${({ theme }) => theme.colors.navy};
	margin: 10px auto 0 auto;
	text-align: center;
`
// prettier-ignore
const ArticlesFeed = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 2fr;
	margin: 30px auto;
	row-gap: 10px;

	a:nth-of-type(n + 3) {
		display: none;
	}

	${({ theme }) => theme.MQ.M} {
		display: grid;
		column-gap: 10px;
		margin: 20px 20px 40px 20px;
		row-gap: 0;
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(3, 1fr);

		/* FOR IE11*/
		display: -ms-grid;
		-ms-grid-columns: (1fr)[3];
		-ms-grid-rows: (1fr)[2];

		a:nth-of-type(n) {
			display: block;
		}
	}

	${({ theme }) => theme.MQ.XL} {
		margin: 20px auto 40px auto;
	}
`
const MoreArticles = styled(Link)`
	${({ theme }) => theme.buttons.default};
	margin: auto;
`
ArticleSection.propTypes = { articles: array.isRequired }
/**
 * This is the Articles section of the Home Page
 * Pulls in the Short Content Card for our latest articles feed
 */
function ArticleSection({ articles }) {
	return (
		<SectionWrapper>
			<ArticlesWrapper>
				<SubHeader>Stay Up To Date</SubHeader>
				<Header>Read our latest articles.</Header>
				<ArticlesFeed>
					{articles &&
						articles.map((article, i) => {
							// this is to support css grid in IE11, set the styles here to keep the card components clean
							const gridBrowserSupport =
								i === 0
									? '-ms-grid-column: 1; -ms-grid-row: 1'
									: i === 1
									? '-ms-grid-column: 2; -ms-grid-row: 1'
									: i === 2
									? '-ms-grid-column: 3; -ms-grid-row: 1'
									: i === 3
									? '-ms-grid-column: 1; -ms-grid-row: 2'
									: i === 4
									? '-ms-grid-column: 2; -ms-grid-row: 2'
									: '-ms-grid-column: 3; -ms-grid-row: 2'

							return (
								<CardShortContent
									date={article.modified}
									gridBrowserSupport={gridBrowserSupport}
									key={i}
									sponsored={article.isSponsored}
									title={article.title}
									url={article.path}
								/>
							)
						})}
				</ArticlesFeed>
				<MoreArticles href="/content/">More Articles</MoreArticles>
			</ArticlesWrapper>
		</SectionWrapper>
	)
}

export default ArticleSection
