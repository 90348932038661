import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import chunk from 'lodash.chunk'
import { Link } from '@jelly/ui'

const topCatLinks = [
	{
		text: 'Access Control Systems',
		path: '/access-control-systems/best/',
	},
	{
		text: 'Background Check Companies',
		path: '/categories/background-check-companies/',
	},
	{
		text: 'Business Liability Insurance Providers',
		path: '/categories/best-business-liability-insurance/',
	},
	{
		text: 'Business Loan and Financing Options',
		path: '/categories/small-business-loans-and-financing-options/',
	},
	{
		text: 'Call Center Services',
		path: '/categories/call-center-services/',
	},
	{
		text: 'Cloud Storage & Online Backup Services',
		path: '/categories/cloud-storage-and-online-backup/',
	},
	{
		text: 'Collection Agency Services',
		path: '/categories/best-collections-agency-services/',
	},
	{
		text: 'CRM Software',
		path: '/categories/crm-software/',
	},
	{
		text: 'Document Management Software',
		path: '/categories/document-management-software/',
	},
	{
		text: 'Electronic Medical Records Software',
		path: '/categories/best-electronic-medical-records-software/',
	},
	{
		text: 'Employee Monitoring Software',
		path: '/categories/employee-monitoring-software/',
	},
	{
		text: 'GPS Fleet Tracking Services',
		path: '/categories/best-gps-fleet-tracking-services/',
	},
	{
		text: 'Online Payroll Services',
		path: '/categories/online-payroll-services/',
	},
	{
		text: 'Remote PC Access Software',
		path: '/categories/best-remote-pc-access-software/',
	},
	{
		text: 'Text Message Marketing Services',
		path: '/categories/best-text-message-marketing-services/',
	},
]

// styled components via emotion
const TopCatWrapper = styled.section`
	${({ theme }) => theme.maxWidth.L};
	background: #fafbfc;
	margin: auto;
	padding: 40px 0;
	text-align: center;
	position: relative;
	width: 100%;

	${({ theme }) => theme.MQ.M} {
		margin: 0 auto 70px auto;
		padding: 0;
		background: none;
	}
`
const DividerPosition = styled.div`
	display: none;

	${({ theme }) => theme.MQ.M} {
		display: block;
		margin: 0 auto;
		width: 50%;
	}
`
const DividerWrapper = styled.div`
	margin: auto;
	padding: 0 50px;
	width: 80%;
`
const Divider = styled.div`
	background: ${({ theme }) => theme.colors.lightGrey};
	height: 3px;
	width: 100%;

	${({ theme }) => theme.MQ.XL} {
		width: 460px;
	}
`
const SectionHeading = styled.div`
	padding-top: 0;
	${({ theme }) => theme.MQ.M} {
		padding-top: 75px;
	}
`
const SubHeader = styled.div`
	${({ theme }) => theme.text.sectionHeaderSmall};
	margin-bottom: 10px;

	${({ theme }) => theme.MQ.M} {
		margin-bottom: 15px;
	}
`
const Header = styled.div`
	${({ theme }) => theme.header.h2};
	color: ${({ theme }) => theme.colors.navy};
	margin-bottom: 30px;

	${({ theme }) => theme.MQ.M} {
		margin-bottom: 35px;
	}
`
const ListOfCategories = styled.div`
	display: block;
	height: auto;
	margin-bottom: 25px;
	width: 100%;

	${({ theme }) => theme.MQ.M} {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 35px;
	}
`
const LinkSection = styled.div`
	display: block;

	${({ theme }) => theme.MQ.M} {
		display: inline-block;
		width: 50%;
	}

	${({ theme }) => theme.MQ.L} {
		width: 33.33%;
	}
`
const StyledListItem = styled.li`
	list-style: none;
	padding: 14px 0;

	${({ theme }) => theme.MQ.S} {
		padding: 19px 0;
	}
`

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.colors.gray[500]};
	font-size: 14px;

	&:hover {
		color: ${({ theme }) => theme.colors.secondary};
	}

	${({ theme }) => theme.MQ.S} {
		font-size: 16px;
	}
`
const CategoriesButton = styled(Link)`
	${({ theme }) => theme.buttons.default};
	margin: auto;
`

function TopCategories({ theme }) {
	const [linkChunks, setLinkChunks] = useState([])

	useEffect(() => {
		const determineChunkSize = () => {
			const windowWidth = window.matchMedia(`(max-width: ${theme.BREAKPOINTS.M})`)
			const numOfGroups = windowWidth.matches ? 2 : 3
			const size = Math.ceil(topCatLinks.length / numOfGroups) // round up to declare how many elements in each group
			return size
		}
		const setTopCategoryGroups = () => {
			setLinkChunks(chunk(topCatLinks, determineChunkSize()))
		}

		setTopCategoryGroups()

		window.addEventListener('resize', setTopCategoryGroups)

		return () => {
			window.removeEventListener('resize', setTopCategoryGroups)
		}
	}, [theme.BREAKPOINTS.M])

	return (
		<TopCatWrapper>
			<DividerPosition>
				<DividerWrapper>
					<Divider />
				</DividerWrapper>
			</DividerPosition>
			<SectionHeading>
				<SubHeader>Find What You Need Fast</SubHeader>
				<Header>View our best picks.</Header>
			</SectionHeading>
			<ListOfCategories>
				{linkChunks.map((linkChunk, i) => {
					return (
						<LinkSection key={i}>
							{linkChunk.map((link) => {
								return (
									<StyledListItem key={link.text}>
										<StyledLink href={link.path}>{link.text}</StyledLink>
									</StyledListItem>
								)
							})}
						</LinkSection>
					)
				})}
			</ListOfCategories>
			<CategoriesButton href="/buying-guides/">See All Categories</CategoriesButton>
		</TopCatWrapper>
	)
}

export default withTheme(TopCategories)
