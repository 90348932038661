import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { array } from 'prop-types'

// Import Components
import { CardProfilePreview } from '@jelly/components'

// Styled Components via Emotion
const SectionWrapper = styled.section`
	overflow: hidden;
	position: relative;
	width: 100%;

	${({ theme }) => theme.MQ.M} {
		height: 50vw;
	}

	${({ theme }) => theme.MQ.XL} {
		${({ theme }) => theme.maxWidth.L};
		height: 600px;
		margin: auto;
	}
`
// prettier-ignore
// because it's messing up the format of -ms-grid-* stuff below
const specialIEGridStyles = css`
	/* FOR IE11*/
	display: -ms-grid;
		-ms-grid-columns: (1fr)[2];
		-ms-grid-rows: (1fr)[2];
`
const ExpertGridWrapper = styled.div`
	display: none;

	${({ theme }) => theme.MQ.M} {
		display: grid;
		height: 100%;
		max-width: 50%;
		position: absolute;
		width: 100%;

		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(2, 1fr);

		/* FOR IE11*/
		${specialIEGridStyles}
	}
`
const ExpertContentWrapper = styled.div`
	display: block;
	height: 100%;
	position: relative;
	width: 100%;

	${({ theme }) => theme.MQ.M} {
		display: inline-block;
		margin-left: auto;
		max-width: 50%;
		position: absolute;
		right: 0;
	}
`
const ExpertContent = styled.div`
	margin: 50px auto;
	width: 80%;

	${({ theme }) => theme.BMQ.ML} {
		bottom: 0;
		margin: 20% auto;
	}

	${({ theme }) => theme.MQ.L} {
		bottom: 0;
		margin: 0;
		margin-top: 30%;
		margin-left: auto;
		right: 0;
	}
`
const SubHeader = styled.div`
	${({ theme }) => theme.text.sectionHeaderSmall};
`
const Header = styled.h2`
	${({ theme }) => theme.header.h2};
	color: ${({ theme }) => theme.colors.primary};
	margin-top: 0.5rem;
	margin-bottom: 0;
`
const ExpertDescription = styled.div`
	color: ${({ theme }) => theme.colors.darkGrey};
	${({ theme }) => theme.text.bodyLarge};
	margin-bottom: 1.5rem;
	margin-top: 1rem;
`
ExpertSection.propTypes = { experts: array.isRequired }

/**
 * This is the Experts Component on the Home Page
 * Should show a grid of 4 experts, and content
 */
function ExpertSection({ experts }) {
	return (
		<SectionWrapper>
			<ExpertGridWrapper>
				{experts.map((expert, i) => {
					// this is to support css grid in IE11, set the styles here to keep the card components clean
					const gridBrowserSupport =
						i === 0
							? '-ms-grid-column: 1; -ms-grid-row: 1'
							: i === 1
							? '-ms-grid-column: 2; -ms-grid-row: 1'
							: i === 2
							? '-ms-grid-column: 1; -ms-grid-row: 2'
							: '-ms-grid-column: 2; -ms-grid-row: 2'

					return (
						<CardProfilePreview
							background={expert.imageUrl}
							company={expert.company}
							gridBrowserSupport={gridBrowserSupport}
							industry={expert.industry}
							key={i}
							jobTitle={expert.jobTitle}
							name={expert.name}
						/>
					)
				})}
			</ExpertGridWrapper>
			<ExpertContentWrapper>
				<ExpertContent>
					<SubHeader>Get Professional Input</SubHeader>
					<Header>Seek expert buying advice.</Header>
					<ExpertDescription>
						Get reliable and informed answers from industry experts and our experienced editorial team who
						research and report on topics important to your business.
					</ExpertDescription>
				</ExpertContent>
			</ExpertContentWrapper>
		</SectionWrapper>
	)
}

export default ExpertSection
