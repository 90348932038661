import React from 'react'
import styled from '@emotion/styled'

// Import Components
import CardLongContent from 'components/CardLongContent'
import { Link } from '@jelly/ui'

const CONNECT_PAGE = '/connect/'

// Styled Components via Emotion
const ConnectWrapper = styled.section`
	height: 100%;
	position: relative;
	padding-bottom: 10px;

	${({ theme }) => theme.MQ.M} {
		padding-bottom: 0;
	}

	${({ theme }) => theme.MQ.XL} {
		${({ theme }) => theme.maxWidth.L};
		max-height: 600px;
		height: 50vw;
		margin: auto;
	}
`
const ConnectContextWrapper = styled.div`
	display: block;
	position: relative;
	margin: 50px auto;
	width: 80%;

	${({ theme }) => theme.MQ.M} {
		display: inline-block;
		margin: 0;
		height: 600px;
		width: 50%;
	}
`
const ConnectContext = styled.div`
	margin: auto;

	${({ theme }) => theme.MQ.M} {
		margin: 35% 50px 0 50px;
	}
`
const ConnectLinks = styled.div`
	display: none;

	${({ theme }) => theme.MQ.M} {
		display: inline-block;
		margin-left: auto;
		position: absolute;
		right: 0;
		width: 50%;
	}
`
const SubHeader = styled.div`
	${({ theme }) => theme.text.sectionHeaderSmall};
`
const Header = styled.h2`
	${({ theme }) => theme.header.h2};
	color: ${({ theme }) => theme.colors.primary};
	margin-top: 0;
	margin-bottom: 5px;
`
const ConnectDescription = styled.div`
	color: ${({ theme }) => theme.colors.darkGrey};
	${({ theme }) => theme.text.bodyLarge};
	margin-bottom: 25px;
	width: 100%;

	${({ theme }) => theme.MQ.L} {
		width: 80%;
	}
`
const ConnectButton = styled(Link)`
	${({ theme }) => theme.buttons.default};
	margin: auto;

	${({ theme }) => theme.MQ.M} {
		display: none;
	}
`
/**
 * This is the Connect With Us Section of the Home Page
 * CTA: different links depending on whether you're signed in or not
 * Mobile only: button that says Advertise With Us
 */
function Connect() {
	return (
		<ConnectWrapper>
			<ConnectContextWrapper>
				<ConnectContext>
					<SubHeader>Be Involved</SubHeader>
					<Header>Connect with us.</Header>
					<ConnectDescription>
						Increase your product or service’s visibility with the help of our experienced team and network
						of business professionals.
					</ConnectDescription>
				</ConnectContext>
				<ConnectButton href={CONNECT_PAGE}>Advertise With Us</ConnectButton>
			</ConnectContextWrapper>
			<ConnectLinks>
				<CardLongContent
					background="https://www.business.com/images/home/connect/expert_connect.jpg"
					description="Drive traffic and qualified leads to your business using our outcome-marketing services."
					flip={true}
					header="Advertise With Us"
					url={CONNECT_PAGE}
				/>
				<CardLongContent
					background="https://www.business.com/images/home/connect/contribute_connect.jpg"
					description="Join our partner network to monetize your website traffic and add value for your visitors with little effort on your part."
					header="Partner With Us"
					url="https://www.buyerzone.com/affiliates/"
				/>
				<CardLongContent
					background="https://www.business.com/images/home/connect/advertise_connect.jpg"
					description="Check out our full-funnel marketing solutions that can help your company achieve success."
					header="Free Marketing Resources"
					url="/marketing-resources/"
				/>
			</ConnectLinks>
		</ConnectWrapper>
	)
}
export default Connect
