import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import { object } from 'prop-types'

// Import Components
import { BlockMonogramPiece1 } from '@jelly/components'
import { Link } from '@jelly/ui'

// Styled Components via Emotion
const SectionWrapper = styled.section`
	background: ${({ theme }) => theme.colors.lightGrey};
	margin-top: -100px;
	padding-bottom: 8%;

	${({ theme }) => theme.MQ.M} {
		height: ${({ height }) => height - 12}px;
		margin: auto;
	}

	@media (min-width: 1100px) {
		background: none;
		font-size: 25px;
		padding-bottom: 0;
	}

	${({ theme }) => theme.MQ.XL} {
		height: 536px;
		${({ theme }) => theme.maxWidth.L};
	}
`
const MonogramWrapper = styled.div`
	display: none;
	height: auto;
	opacity: 0.05;
	position: absolute;
	max-width: 600px;
	width: 50%;
	z-index: -1;

	@media (min-width: 1100px) {
		display: block;
	}
`
const StatementWrapper = styled.div`
	${({ theme }) => theme.maxWidth.L};
	padding-top: 150px;
	width: 100%;

	${({ theme }) => theme.MQ.M} {
		padding-top: 220px;
	}

	${({ theme }) => theme.BMQ.ML} {
		padding-top: 400px;
	}

	${({ theme }) => theme.MQ.L} and (max-height: 590px) {
		padding-top: 25%;
	}

	${({ theme }) => theme.MQ.L} and (max-height: 590px) {
		padding-top: 300px;
	}

	${({ theme }) => theme.MQ.L} and (min-height: 1300px) {
		padding-top: 100px;
	}

	@media (min-width: 1100px) {
		margin-left: auto;
		padding-bottom: 0;
		right: 0;
		width: 80%;
	}
`
const Header = styled.div`
	${({ theme }) => theme.text.sectionHeaderSmall};
`
const MissionStatement = styled.div`
	margin: auto;
	width: 80%;

	${({ theme }) => theme.MQ.M} {
		margin-left: auto;
		margin-right: 80px;
		margin: auto;
		max-width: 900px;
		right: 0;
		width: 90%;
	}
	@media (min-width: 1100px) {
		margin-left: auto;
		margin-right: 0;
		max-width: 700px;
		right: 0;
		width: 100%;
	}
`
const Mission = styled.div`
	color: ${({ theme }) => theme.colors.primary};
	font-size: 1.5rem;
	margin-bottom: 20px;
	margin-top: 5px;
`
const AboutUs = styled(Link)`
	${({ theme }) => theme.buttons.default};
	display: block;
	margin: auto;

	${({ theme }) => theme.MQ.M} {
		margin: 0;
	}
`

AboutSection.propTypes = { theme: object.isRequired }

/**
 * This is the About Section of the Home Page
 * CTA: leads to /abous-us
 */
function AboutSection({ theme }) {
	const [monogramHeight, setMonogramHeight] = useState()

	useEffect(() => {
		setMonogramHeight(document.getElementById('blockmonogram').offsetHeight)
		window.addEventListener('resize', () => {
			setMonogramHeight(document.getElementById('blockmonogram').offsetHeight)
		})
	}, [monogramHeight])

	return (
		<SectionWrapper height={monogramHeight}>
			<MonogramWrapper id="blockmonogram">
				<BlockMonogramPiece1 color={theme.colors.navy} />
			</MonogramWrapper>
			<StatementWrapper>
				<MissionStatement>
					<Header> Our Mission </Header>
					<Mission>
						Our mission is to help you take your team, your business and your career to the next level.
						Whether you're here for product recommendations, research or career advice, we're happy you're
						here!{' '}
					</Mission>
					<AboutUs href="/about-us/">About Us</AboutUs>
				</MissionStatement>
			</StatementWrapper>
		</SectionWrapper>
	)
}

export default withTheme(AboutSection)
