import React from 'react'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import { css } from '@emotion/core'
import { array, object } from 'prop-types'

// Import Components
import CardPhotoContent from 'components/CardPhotoContent'
import { BlockMonogramPiece2 } from '@jelly/components'
import { Link } from '@jelly/ui'

// Styled Components via Emotion
const SectionWrapper = styled.section`
	position: relative;
	height: 100%;
	padding-bottom: 30px;

	${({ theme }) => theme.MQ.M} {
		height: 50vw;
		padding-bottom: 0;
	}

	${({ theme }) => theme.MQ.L} {
		${({ theme }) => theme.maxWidth.L};
		max-height: 600px;
		margin: auto;
	}
`
const ReviewContentWrapper = styled.div`
	display: block;
	position: relative;
	width: 100%;

	${({ theme }) => theme.MQ.M} {
		display: inline-block;
		max-width: 50%;
		position: absolute;
	}
`
// prettier-ignore
// because it's messing up the format of -ms-grid-* stuff below
const specialIEGridStyles = css`
	/* FOR IE11 */
	display: -ms-grid;
	-ms-grid-columns: (1fr)[2];
	-ms-grid-rows: (1fr)[2];
`

const ReviewCardsGrid = styled.div`
	display: none;

	${({ theme }) => theme.MQ.M} {
		display: grid;
		height: 100%;
		margin-left: auto;
		position: relative;
		right: 0;
		width: 100%;

		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);

		/* FOR IE11 */
		${specialIEGridStyles}
	}

	${({ theme }) => theme.MQ.M} {
		max-width: 50%;
		position: absolute;
	}
`

const MonogramWrapper = styled.div`
	display: none;

	@media (min-width: 1100px) {
		display: block;
		opacity: 0.05;
		width: 100%;
	}
`
const ReviewContent = styled.div`
	margin: 50px auto;
	width: 80%;

	${({ theme }) => theme.MQ.M} {
		margin-top: 10%;
		padding: 0 50px;
	}
`
const SubHeader = styled.span`
	${({ theme }) => theme.text.sectionHeaderSmall};
`
const Header = styled.h2`
	${({ theme }) => theme.header.h2};
	color: ${({ theme }) => theme.colors.navy};
	margin-top: 0;
	margin-bottom: 5px;
`
const ReviewDescription = styled.div`
	color: ${({ theme }) => theme.colors.darkGrey};
	${({ theme }) => theme.text.bodyLarge};
	margin-bottom: 25px;
`
const ReviewButtonDesktop = styled(Link)`
	${({ theme }) => theme.buttons.default};
	display: none;

	${({ theme }) => theme.MQ.M} {
		display: block;
	}
`
const ReviewButtonMobile = styled(Link)`
	${({ theme }) => theme.buttons.default};
	margin: 40px auto;

	${({ theme }) => theme.MQ.M} {
		display: none;
	}
`

ReviewSection.propTypes = {
	reviews: array.isRequired,
	theme: object.isRequired,
}

/**
 * This is the Reviews Component in the Home Page
 * This will show a grid of 4 different review categories
 * Uses the Photo Content Component for the Review Cards
 */
function ReviewSection({ reviews, theme }) {
	return (
		<SectionWrapper>
			<ReviewContentWrapper>
				<MonogramWrapper>
					<BlockMonogramPiece2 color={theme.colors.navy} />
				</MonogramWrapper>
				<ReviewContent>
					<SubHeader>Be Informed</SubHeader>
					<Header>Explore our reviews.</Header>
					<ReviewDescription>
						Our team of independent researchers evaluate the business solutions by comparing prices and
						features to help you make smarter buying decisions.
					</ReviewDescription>
					<ReviewButtonDesktop href="/reviews/">More Reviews</ReviewButtonDesktop>
					<ReviewButtonMobile href="/reviews/">See All Reviews</ReviewButtonMobile>
				</ReviewContent>
			</ReviewContentWrapper>

			<ReviewCardsGrid>
				{reviews.map((review, i) => {
					// this is to support css grid in IE11, set the styles here to keep the card components clean
					const gridBrowserSupport =
						i === 0
							? '-ms-grid-column: 1; -ms-grid-row: 1'
							: i === 1
							? '-ms-grid-column: 2; -ms-grid-row: 1'
							: i === 2
							? '-ms-grid-column: 1; -ms-grid-row: 2'
							: '-ms-grid-column: 2; -ms-grid-row: 2'
					return (
						<CardPhotoContent
							background={review.imageUrl}
							category={review.categoryTag}
							description={review.description}
							gridBrowserSupport={gridBrowserSupport}
							id={i}
							key={i}
							url={review.path}
						/>
					)
				})}
			</ReviewCardsGrid>
		</SectionWrapper>
	)
}

export default withTheme(ReviewSection)
